import React from 'react';
import styled from 'styled-components';
import { fontSize, minBreakpointQuery, brandColours } from '../styles';
import { Button } from './ui';

const StyledTradeInfo = styled.div`
  text-align: center;
  padding: 30px 20px;

  ${minBreakpointQuery.smedium`
    background-color: ${brandColours.primary};
  `}
`;

const StyledHeading = styled.h3`
  ${fontSize(26)};
`;

const StyledText = styled.p`
  ${fontSize(14)};
  line-height: 1.5;
  margin-top: 15px;
  margin-bottom: 20px;

  ${minBreakpointQuery.smedium`
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledButton = styled(Button)``;

const TradeInfo = ({ heading, text, link, ...props }) => (
  <StyledTradeInfo {...props}>
    <StyledHeading>{heading}</StyledHeading>
    <StyledText>{text}</StyledText>
    <StyledButton to={link.page} alt={true}>
      {link.text}
    </StyledButton>
  </StyledTradeInfo>
);

export default TradeInfo;
