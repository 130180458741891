import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { getSingleLocaleFields, formatPrice } from '../utils';
import { StoreContext } from '../context/StoreContext';

const StyledFreeDeliveryMessage = styled.div``;

const FreeDeliveryMessage = ({ locale, ...props }) => {
  const { allDatoCmsFreeDeliveryThresholdsSitewide } = useStaticQuery(graphql`
    query FreeDeliveryMessageQuery {
      allDatoCmsFreeDeliveryThresholdsSitewide {
        nodes {
          locale
          sitewideNotice
          gbp
          usd
          cad
          aud
          nzd
          eur
          hkd
          chf
          aed
          sar
        }
      }
    }
  `);

  const freeDeliveryThresholdsSitewide = getSingleLocaleFields(
    allDatoCmsFreeDeliveryThresholdsSitewide,
    locale
  );

  const { currency } = useContext(StoreContext);

  return (
    freeDeliveryThresholdsSitewide && (
      <StyledFreeDeliveryMessage
        dangerouslySetInnerHTML={{
          __html: freeDeliveryThresholdsSitewide.sitewideNotice
            .replace(
              '{amount}',
              formatPrice({
                amount: freeDeliveryThresholdsSitewide[currency.toLowerCase()],
                currencyCode: currency,
                locale: locale,
              })
            )
            .replace('delivery', () =>
              currency === 'USD' ? 'shipping' : 'delivery'
            ),
        }}
        {...props}
      />
    )
  );
};

export default FreeDeliveryMessage;
