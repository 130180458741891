import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  brandColours,
  standardColours,
  fontSize,
  brandFonts,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardTransition,
  visuallyHidden,
  fontWeights,
} from '../styles';
import { Container, Svg, Link } from './ui';
import Logo from './Logo';
import TradeInfo from './TradeInfo';
import { getSingleLocaleFields, currencyOptions, getFlag } from '../utils';
import instagramIcon from '../images/instagram-icon.inline.svg';
import pinterestIcon from '../images/pinterest-icon.inline.svg';
import padlockIcon from '../images/padlock-icon.inline.svg';

const StyledFooter = styled.footer`
  color: ${standardColours.white};
  background-color: ${brandColours.secondary};
  padding-bottom: 50px;

  ${minBreakpointQuery.large`
    position: relative;
  `}
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.xxxlarge`
    position: relative;
  `}
`;

const StyledTop = styled.div`
  position: relative;
  padding-top: 50px;
  padding-bottom: 40px;

  ${minBreakpointQuery.mlarge`
    padding-top: 100px;
    padding-bottom: 100px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 120px;
    padding-bottom: 100px;
  `}

  ${minBreakpointQuery.xlarge`
    padding-top: 140px;
    padding-bottom: 100px;
  `}
`;

const StyledLogoMobile = styled(Logo)`
  position: absolute;
  transform-origin: bottom right;
  right: 0;
  bottom: 40px;
  transform: rotate(-90deg) translateX(100%);

  ${minBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  img {
    width: 460px;
  }
`;

const StyledLogo = styled(Logo)`
  position: absolute;
  transform-origin: top left;
  left: 2%;
  top: 100px;
  transform: rotate(-90deg) translateX(-100%);

  ${maxBreakpointQuery.xxxlarge`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.xxxlarge`
  `}

  img {
    width: 460px;
    ${minBreakpointQuery.xxxlarge`
      width: 564px;
    `}
  }
`;

const StyledSocial = styled.div`
  text-align: center;

  ${maxBreakpointQuery.mlarge`
    margin: 25px;
  `}

  ${minBreakpointQuery.mlarge`
    flex: 1;
    order: 1;
  `}
`;

const StyledSocialLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  background-color: ${brandColours.primary};
  border-radius: 50%;
  margin: 0 5px;
  transition: ${standardTransition('background-color')};

  &:hover {
    background-color: ${brandColours.secondary};
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledIcon = styled(Svg)`
  width: 23px;
  height: 23px;
`;

const StyledLockIcon = styled(Svg)`
  width: 12px;
  height: 12px;
`;

const StyledLists = styled.div`
  display: grid;
  row-gap: 30px;

  ${minBreakpointQuery.tsmall`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: 300px repeat(3, 1fr);
    grid-column: 1 / 3;
    column-gap: 50px;
  `}

  ${minBreakpointQuery.large`
    column-gap: 80px;
  `}

  ${minBreakpointQuery.xlarge`
    column-gap: 100px;
  `}

  ${minBreakpointQuery.xxlarge`
    column-gap: 120px;
  `}
`;

const StyledGroup = styled.div``;

const StyledHeading = styled.h3`
  ${fontSize(20)};
  font-family: ${brandFonts.secondary};
`;

const StyledList = styled.ul`
  margin-top: 20px;
`;

const StyledListItem = styled.li`
  margin: 12px 0;
`;

const StyledListLink = styled(Link)`
  ${fontSize(16)};
  font-weight: ${fontWeights.regular};
`;

const StyledAddress = styled.p`
  white-space: break-spaces;
  margin-top: 15px;
  margin-bottom: 25px;
  font-weight: ${fontWeights.regular};
  line-height: 1.6;
`;

const StyledPhone = styled.p`
  margin-top: 10px;
`;

const StyledEmail = styled.p`
  margin-top: 10px;
  ${fontSize(14)}
`;

const StyledTradeInfo = styled(TradeInfo)`
  margin: 0 auto;
  max-width: 500px;
  align-self: start;

  ${maxBreakpointQuery.mlarge`
    ${visuallyHidden()}
  `}
`;

const StyledFeaturedLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 30px 0;
  margin-bottom: 35px;
  border-top: 1px solid ${brandColours.secondary};
  border-bottom: 1px solid ${brandColours.secondary};

  ${minBreakpointQuery.mlarge`
    justify-content: space-between;
  `}
`;

const StyledBottom = styled.div`
  ${minBreakpointQuery.mlarge`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  `}
`;

const StyledFeaturedLogo = styled.img`
  height: 38px;
`;

const StyledText = styled.p`
  ${minBreakpointQuery.mlarge`
    display: inline-block;
  `}
`;

const StyledSecureDelivery = styled.p`
  ${fontSize(14)};

  ${maxBreakpointQuery.mlarge`
    text-align: center;
  `}

  ${minBreakpointQuery.mlarge`
    align-self: center;
    flex: 1;
    order: 2;
    text-align: right;
  `}
`;

const StyledFlags = styled.span`
  display: inline-block;
  white-space: nowrap;

  ${maxBreakpointQuery.mlarge`
    margin-top: 10px;
  `}

  ${minBreakpointQuery.mlarge`
    flex: 1;
    margin-left: 10px;
  `}
`;

const StyledFlag = styled.img`
  margin-left: 6px;
  width: 26px;
  height: 26px;
  object-fit: cover;
  border-radius: 50%;

  &:first-child {
    margin-left: 0;
  }
`;

const StyledCopyright = styled.p`
  text-align: center;
  ${fontSize(14)}

  ${minBreakpointQuery.mlarge`
    flex: 1;
    order: 0;
    text-align: left;
  `}
`;

const StyledBackToTop = styled.button`
  background: none;
  border: 1px solid ${standardColours.white};
  text-align: center;
  margin: 0 auto;
  display: block;
  text-transform: uppercase;
  width: 65px;
  color: ${standardColours.white};
  ${fontSize(14)};
  border-radius: 50%;
  padding: 12px 16px;
  margin-top: 25px;

  ${minBreakpointQuery.large`
    margin: 0;
    position: absolute;
    top: 50px;
    right: 2%;
  `}
`;

const Footer = ({ locale }) => {
  const { allDatoCmsFooter, allDatoCmsContactInfo } = useStaticQuery(graphql`
    query FooterQuery {
      allDatoCmsFooter {
        nodes {
          locale
          tradeHeading
          tradeText
          tradeLink {
            text
            page {
              ...LinkFragment
            }
          }
          linkLists {
            id
            heading
            links {
              id
              model {
                apiKey
              }
              text
              page {
                ...LinkFragment
              }
            }
          }
          logos {
            url
            alt
          }
          pinterestProfile
          instagramProfile
        }
      }
      allDatoCmsContactInfo {
        nodes {
          locale
          address
          phoneNumber
          emailAddress
        }
      }
    }
  `);

  const {
    tradeHeading,
    tradeText,
    tradeLink,
    linkLists,
    logos,
    pinterestProfile,
    instagramProfile,
  } = getSingleLocaleFields(allDatoCmsFooter, locale);

  const { address, phoneNumber, emailAddress } = getSingleLocaleFields(
    allDatoCmsContactInfo,
    locale
  );

  return (
    <StyledFooter>
      <StyledLogo alt={true} locale={locale} />
      <Container>
        <StyledInner>
          <StyledTop>
            <StyledLogoMobile alt={true} locale={locale} />
            <StyledLists>
              <StyledTradeInfo
                heading={tradeHeading}
                text={tradeText}
                link={tradeLink}
              />
              {linkLists.map(({ id, heading, links }) => (
                <StyledGroup key={id}>
                  <StyledHeading>{heading}</StyledHeading>
                  <StyledList>
                    {links.map(link => (
                      <StyledListItem key={link.id}>
                        {link.model.apiKey === 'link' && (
                          <StyledListLink to={link.page}>
                            {link.text}
                          </StyledListLink>
                        )}
                      </StyledListItem>
                    ))}
                  </StyledList>
                </StyledGroup>
              ))}
              <StyledGroup>
                <StyledHeading>Contact</StyledHeading>
                <StyledAddress>{address}</StyledAddress>
                <StyledPhone>{phoneNumber}</StyledPhone>
                <StyledEmail>{emailAddress}</StyledEmail>
              </StyledGroup>
            </StyledLists>
          </StyledTop>
          <StyledFeaturedLogos>
            {logos.map(({ url, alt }, id) => (
              <StyledFeaturedLogo key={id} src={url} alt={alt} loading="lazy" />
            ))}
          </StyledFeaturedLogos>
          <StyledBottom>
            <StyledSecureDelivery>
              <StyledText>We deliver to</StyledText>
              <StyledFlags>
                {currencyOptions.map((currencyOption, id) => (
                  <StyledFlag
                    key={id}
                    src={getFlag(currencyOption)}
                    alt={currencyOption}
                    loading="lazy"
                  />
                ))}
              </StyledFlags>
            </StyledSecureDelivery>
            <StyledSocial>
              <StyledSocialLink
                href={pinterestProfile}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledIcon image={pinterestIcon} />
                <span>Pinterest</span>
              </StyledSocialLink>
              <StyledSocialLink
                href={instagramProfile}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledIcon image={instagramIcon} />
                <span>Instagram</span>
              </StyledSocialLink>
            </StyledSocial>
            <StyledCopyright>
              &copy; {new Date().getFullYear()} Bobbibeck.{' '}
              <StyledLockIcon image={padlockIcon} /> Shop securely.
            </StyledCopyright>
          </StyledBottom>
        </StyledInner>
      </Container>
      <StyledBackToTop
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        To Top
      </StyledBackToTop>
    </StyledFooter>
  );
};

export default Footer;
