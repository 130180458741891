import React from 'react';
import styled, { css } from 'styled-components';
import { standardColours, brandColours, visuallyHidden } from '../../styles';

const StyledArrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 0;

  ${({ version }) => {
    if (version === 2) {
      return css`
        height: 36px;
        width: 36px;
        background-color: ${standardColours.white};
        border: 1px solid ${standardColours.grey};
        box-shadow: 0px 4px 4px 0px ${standardColours.transparentBlack(0.08)};

        &:disabled {
          border: none;
          box-shadow: none;
          background-color: ${brandColours.senary};
        }
      `;
    } else {
      return css`
        height: 40px;
        width: 40px;
        background-color: ${brandColours.secondary};
        border: none;
        border-radius: 50%;
      `;
    }
  }}

  &:before {
    content: '';
    display: block;
    transform: ${({ alt }) =>
      alt
        ? 'translateX(-25%) rotate(225deg)'
        : 'translateX(25%) rotate(45deg)'};

    ${({ version }) => {
      if (version === 2) {
        return css`
          height: 8px;
          width: 8px;
          border: 2px solid ${standardColours.black};
          border-top: none;
          border-right: none;
        `;
      } else {
        return css`
          height: 10px;
          width: 10px;
          border: 3px solid ${standardColours.white};
          border-top: none;
          border-right: none;
        `;
      }
    }}
  }

  span {
    ${visuallyHidden()};
  }

  &:first-child {
    margin-left: 0;
  }
`;

export const PreviousArrow = ({ alt, version, ...props }) => (
  <StyledArrow version={version} {...props}>
    <span>Previous</span>
  </StyledArrow>
);

export const NextArrow = ({ alt, version, ...props }) => (
  <StyledArrow alt={alt} version={version} {...props}>
    <span>Next</span>
  </StyledArrow>
);
