import { css } from 'styled-components';
import { breakpointSizes, fontWeights, fluidFontSize } from '../../styles';

export const headingStyles = () => {
  return css`
    font-weight: ${fontWeights.semibold};
    ${fluidFontSize(
      '26px',
      '42px',
      breakpointSizes.tiny,
      breakpointSizes.xxxxlarge
    )};
  `;
};
