import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { standardColours, fontSize } from '../styles';
import { Button } from './ui';
import PopOutSidebar, {
  StyledContent,
  StyledFooter,
  StyledSummaryItem,
  StyledNote,
} from './PopOutSidebar';
import CartItem from './CartItem';
import { formatPrice, translateString } from '../utils';
import { StoreContext } from '../context/StoreContext';

const StyledCart = styled(PopOutSidebar)`
  right: 0;
`;

const StyledItems = styled.ul``;

const StyledItem = styled.li`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid ${standardColours.transparentBlack(0.1)};

  &:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
`;

const StyledText = styled.p`
  margin-bottom: 15px;
  ${fontSize(14)};
  white-space: break-spaces;
`;

const StyledButton = styled(Button)`
  width: 100%;
  color: ${standardColours.black};
  background-color: ${standardColours.white};
  border-color: ${standardColours.white};

  &:hover {
    color: ${standardColours.white};
    background-color: ${standardColours.black};
    border-color: ${standardColours.black};
  }
`;

const Cart = ({ locale }) => {
  const { allShopifyProductVariant } = useStaticQuery(graphql`
    query CartQuery {
      allShopifyProductVariant {
        nodes {
          storefrontId
          presentmentPrices {
            price {
              amount
              currencyCode
            }
          }
        }
      }
    }
  `);

  const { isCartOpen, toggleCartOpen, checkout } = useContext(StoreContext);

  const { lineItems: initialLineItems, subtotalPriceV2, webUrl } = checkout;

  const lineItems = initialLineItems.map(initialLineItem => {
    const productVariant = allShopifyProductVariant.nodes.find(
      ({ storefrontId }) => storefrontId === initialLineItem.variant.id
    );

    initialLineItem.variant.presentmentPrices =
      productVariant.presentmentPrices;

    return initialLineItem;
  });

  return (
    <StyledCart
      heading={translateString('cart.header', locale)}
      isOpen={isCartOpen}
      toggleOpen={toggleCartOpen}
    >
      <StyledContent>
        {lineItems.length > 0 ? (
          <StyledItems>
            {lineItems.map(item => (
              <StyledItem key={item.id}>
                <CartItem product={item} locale={locale} />
              </StyledItem>
            ))}
          </StyledItems>
        ) : (
          <>
            <StyledText>{translateString('cart.empty', locale)}</StyledText>
            <Button onClick={toggleCartOpen}>
              {translateString('cart.continue', locale)}
            </Button>
          </>
        )}
      </StyledContent>
      {lineItems.length > 0 && (
        <StyledFooter>
          <StyledSummaryItem>
            <span>{translateString('cart.subtotal', locale)}</span>{' '}
            {formatPrice({
              amount: subtotalPriceV2.amount,
              currencyCode: subtotalPriceV2.currencyCode,
              locale: locale,
            })}
          </StyledSummaryItem>
          <StyledNote>{translateString('cart.message', locale)}</StyledNote>
          <StyledButton
            onClick={() => {
              if (locale === 'fr') {
                const wnd = window.open(webUrl);
                setTimeout(() => {
                  wnd.close();
                  window.location.href = `${webUrl.replace(
                    `/59717550251/checkouts/`,
                    `/checkouts/co/`
                  )}&locale=fr`;
                }, 100);
                // window.location.href = webUrl;
              } else {
                window.location.href = webUrl;
              }
            }}
          >
            {translateString('cart.checkout', locale)}
          </StyledButton>
        </StyledFooter>
      )}
    </StyledCart>
  );
};

export default Cart;
