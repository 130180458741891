import React from 'react';
import styled, { css } from 'styled-components';
import { standardColours, zIndexLayers } from '../../styles';

const StyledWrapper = styled.div`
  position: relative;

  ${({ isOverlayActive }) => {
    if (isOverlayActive) {
      return css`
        &:after {
          content: '';
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: ${isOverlayActive === 'Navigation'
            ? zIndexLayers.third
            : zIndexLayers.fourth};
          background-color: ${standardColours.transparentBlack(0.3)};
          cursor: pointer;
        }
      `;
    }
  }}
`;

export const Wrapper = ({ isOverlayActive, children, ...props }) => (
  <StyledWrapper isOverlayActive={isOverlayActive} {...props}>
    {children}
  </StyledWrapper>
);
