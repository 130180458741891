export const breakpointSizes = {
  xtiny: '360',
  tiny: '400',
  tsmall: '520',
  small: '620',
  smedium: '768',
  medium: '870',
  mlarge: '1020',
  large: '1120',
  xlarge: '1290',
  xxlarge: '1400',
  xxxlarge: '1600',
  xxxxlarge: '2000',
};
