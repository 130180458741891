import React from 'react';
import styled, { css } from 'styled-components';
import { standardColours, fontWeights, standardTransition } from '../../styles';
import { Link } from './Link';

const commonButtonStyles = () => {
  return css`
    display: inline-block;
    padding: 14px 22px;
    color: ${standardColours.white};
    font-weight: ${fontWeights.semibold};
    line-height: 1.5;
    text-align: center;
    background-color: ${({ alt }) =>
      alt ? standardColours.transparent : standardColours.black};
    border: 2px solid
      ${({ alt }) => (alt ? standardColours.white : standardColours.black)};
    border-radius: 5px;
    transition: ${standardTransition('color')},
      ${standardTransition('background-color')},
      ${standardTransition('border-color')};

    &:hover {
      color: ${standardColours.black};
      background-color: ${standardColours.white};
    }
  `;
};

const StyledButton = styled.button`
  ${({ alt }) => commonButtonStyles(alt)}
`;

const StyledButtonLinkInternal = styled(Link)`
  ${({ alt }) => commonButtonStyles(alt)}
`;

const StyledButtonLinkExternal = styled.a`
  ${({ alt }) => commonButtonStyles(alt)}
`;

export const Button = ({ alt, children, ...props }) => {
  const ButtonComponent = props.to
    ? StyledButtonLinkInternal
    : props.href
    ? StyledButtonLinkExternal
    : StyledButton;

  return (
    <ButtonComponent alt={alt} {...props}>
      {children}
    </ButtonComponent>
  );
};
