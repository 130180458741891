export const standardColours = {
  black: '#000',
  lightBlack: '#181818',
  darkestGrey: '#4F4F4F',
  darkerGrey: '#828282',
  darkGrey: '#B6B6B6',
  grey: '#D1D7DA',
  lightGrey: '#E0E0E0',
  lighterGrey: '#F4F4F4',
  lightestGrey: '#F8F8F8',
  white: '#FFF',
  transparentBlack: value => {
    return `rgba(0, 0, 0, ${value})`;
  },
  transparentWhite: value => {
    return `rgba(255, 255, 255, ${value})`;
  },
  transparent: 'rgba(255, 255, 255, 0)',
};

export const brandColours = {
  primary: '#003B49', // dark teal
  secondary: '#002B36', // darker teal
  tertiary: '#F1E8E4', // beige
  quaternary: '#CDC0B6', // dark beige
  quinary: '#c75249', // red
  senary: '#F1E7E4', // light pink
  septenary: '#F2C94C', // yellow
};
