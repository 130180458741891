import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  fontSize,
  zIndexLayers,
} from '../styles';
import { Container, HtmlContent, Button } from './ui';
import { getSingleLocaleFields, slugify } from '../utils';

const StyledCookieNotice = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 25px 0;
  width: 100%;
  background-color: ${brandColours.tertiary};
  z-index: ${zIndexLayers.sixth};
`;

const StyledInner = styled.div`
  display: flex;
  justify-content: center;

  ${maxBreakpointQuery.tsmall`
    flex-direction: column;
  `}

  ${minBreakpointQuery.tsmall`
    align-items: center;
  `}
`;

const StyledContent = styled.div``;

const StyledHeading = styled.h3`
  ${fontSize(15)};

  ${minBreakpointQuery.small`
    ${fontSize(16)};
  `}
`;

const StyledText = styled(HtmlContent)`
  margin-top: 10px;

  p {
    ${fontSize(13)};

    ${minBreakpointQuery.small`
      ${fontSize(14)};
    `}
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;

  ${maxBreakpointQuery.tsmall`
    margin-top: 12px;
  `}

  ${minBreakpointQuery.tsmall`
    margin-left: 15px;
  `}
`;

const CookieNotice = ({ locale }) => {
  const {
    datoCmsSite: { name },
    allDatoCmsCookieNotice,
  } = useStaticQuery(graphql`
    query CookieNoticeQuery {
      datoCmsSite {
        name
      }
      allDatoCmsCookieNotice {
        nodes {
          locale
          heading
          text
        }
      }
    }
  `);

  const { heading, text } = getSingleLocaleFields(
    allDatoCmsCookieNotice,
    locale
  );

  const localStorageKey = `${slugify(name)}:accept-cookie-notice`;
  const [acceptedCookie, setAcceptedCookie] = useState();

  useEffect(() => {
    setAcceptedCookie(localStorage.getItem(localStorageKey) || false);
  }, [localStorageKey]);

  const acceptCookieNotice = () => {
    setAcceptedCookie(true);
    localStorage.setItem(localStorageKey, true);
  };

  return acceptedCookie === false ? (
    <StyledCookieNotice>
      <Container>
        <StyledInner>
          <StyledContent>
            <StyledHeading>{heading}</StyledHeading>
            <StyledText html={text} />
          </StyledContent>
          <StyledButton onClick={acceptCookieNotice}>Accept</StyledButton>
        </StyledInner>
      </Container>
    </StyledCookieNotice>
  ) : (
    ''
  );
};

export default CookieNotice;
