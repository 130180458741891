import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
  zIndexLayers,
  standardTransition,
  visuallyHidden,
  brandColours,
} from '../styles';
import { Svg, Link, Container } from './ui';
import Logo from './Logo';
import SitewideNotice from './SitewideNotice';
import Navigation from './Navigation';
import Search from './Search';
import { getFlag, checkIfMural } from '../utils';
import { StoreContext } from '../context/StoreContext';
import cartIcon from '../images/cart-icon.inline.svg';

const StyledHeader = styled.header`
  position: relative;
  z-index: ${zIndexLayers.fourth};
  background-color: ${standardColours.white};
  border-bottom: 1px solid ${standardColours.lightGrey};
  transition: ${standardTransition('transform')};
`;

const StyledTop = styled.div`
  padding: 10px 0;

  ${minBreakpointQuery.mlarge`
    padding-top: 24px;
    padding-bottom: 24px;
  `}
`;

const StyledTopInner = styled.div`
  ${minBreakpointQuery.mlarge`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledLogo = styled(Logo)`
  ${maxBreakpointQuery.mlarge`
    justify-self: center;
  `}

  ${minBreakpointQuery.mlarge`
    position: relative;
    bottom: 2px;
  `}

  ${({ mobile, desktop }) => {
    if (mobile) {
      return css`
        ${minBreakpointQuery.mlarge`
          ${visuallyHidden()};
        `}
      `;
    } else if (desktop) {
      return css`
        ${maxBreakpointQuery.mlarge`
          ${visuallyHidden()};
        `}
      `;
    }
  }}
`;

const StyledBottom = styled.div`
  border-top: 1px solid ${standardColours.lightGrey};

  ${maxBreakpointQuery.mlarge`
    padding-top: 20px;
  `}
`;

const StyledBottomInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  ${minBreakpointQuery.tsmall`
    display: grid;
    grid-template-columns: 128px 1fr 128px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: 132px 1fr 132px;
  `}

  ${minBreakpointQuery.mlarge`
    display: flex;
  `}
`;

const StyledNavToggle = styled.button`
  ${maxBreakpointQuery.mlarge`
    justify-self: start;
    padding: 9px 11px;
    background: none;
    border: 1px solid ${standardColours.lightGrey};
  `}

  ${minBreakpointQuery.mlarge`
    display: none;
  `}

  span {
    span {
      ${maxBreakpointQuery.mlarge`
        display: block;
        margin-top: 3px;
        margin-bottom: 3px;
        height: 2px;
        width: 16px;
        background-color: ${standardColours.lightBlack};
        border-radius: 1px;
        transition: ${standardTransition('opacity')},
          ${standardTransition('transform')};
      `}

      ${({ isNavOpen }) => {
        if (isNavOpen) {
          return css`
            ${maxBreakpointQuery.mlarge`
              opacity: 0;
            `}

            &:first-child {
              ${maxBreakpointQuery.mlarge`
                opacity: 1;
                transform: translateY(5px) rotate(45deg);
              `}
            }

            &:last-child {
              ${maxBreakpointQuery.mlarge`
                opacity: 1;
                transform: translateY(-5px) rotate(-45deg);
              `}
            }
          `;
        }
      }}
    }
  }
`;

const StyledNavigation = styled(Navigation)``;

const StyledCurrencyCart = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledCurrency = styled.button`
  padding: 10px;
  font-weight: ${fontWeights.medium};
  ${fontSize(12)};
  line-height: 1;
  background: none;
  border: 1px solid ${standardColours.lightGrey};
  white-space: nowrap;
`;

const StyledFlag = styled.img`
  display: inline-block;
  margin-right: 5px;
  width: 24px;
  height: 16px;
  object-fit: cover;
`;

const StyledCart = styled(Link)`
  padding: 6px 10px;
  background: none;
  border: none;
  position: relative;
  border: 1px solid ${standardColours.lightGrey};

  span {
    ${visuallyHidden()};
  }

  svg {
    display: block;
    height: 18px;
    width: 18px;

    ${minBreakpointQuery.smedium`
      height: 22px;
      width: 22px;
    `}

    ${minBreakpointQuery.mlarge`
      height: 18px;
      width: 18px;
    `}
  }
`;

const StyledQuantity = styled.strong`
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 2px;
  height: 22px;
  min-width: 22px;
  color: ${standardColours.white};
  ${fontSize(12)};
  line-height: 18px;
  text-align: center;
  background-color: ${brandColours.primary};
  border-radius: 50%;
  font-weight: ${fontWeights.medium};
`;

const StyledSearch = styled(Search)`
  padding: 20px 0;

  ${minBreakpointQuery.mlarge`
    padding-top: 0;
    padding-bottom: 24px;
  `}
`;

const Header = ({ locale, isNavOpen, setNavOpen, setOverlayActive }) => {
  const { toggleCartOpen, currency, toggleLocationSettingsOpen, checkout } =
    useContext(StoreContext);

  const cartQuantity = checkout.lineItems.reduce((total, item) => {
    const qty = checkIfMural(item.variant.sku) ? 1 : item.quantity;
    return total + qty;
  }, 0);

  return (
    <StyledHeader>
      <StyledTop>
        <Container>
          <StyledTopInner>
            <StyledLogo desktop={true} locale={locale} />
            <SitewideNotice locale={locale} />
          </StyledTopInner>
        </Container>
      </StyledTop>
      <StyledBottom>
        <Container>
          <StyledBottomInner>
            <StyledNavToggle
              aria-label="Open / Close Navigation"
              isNavOpen={isNavOpen}
              onClick={() => setNavOpen(!isNavOpen)}
            >
              <span>
                <span />
                <span />
                <span />
              </span>
            </StyledNavToggle>
            <StyledNavigation
              locale={locale}
              isNavOpen={isNavOpen}
              setOverlayActive={setOverlayActive}
            />
            <StyledLogo mobile={true} locale={locale} />
            <StyledCurrencyCart>
              <StyledCurrency onClick={toggleLocationSettingsOpen}>
                <StyledFlag
                  src={getFlag(currency)}
                  alt={currency}
                  loading="lazy"
                />
                {currency}
              </StyledCurrency>
              <StyledCart onClick={toggleCartOpen}>
                <span>Open Cart</span>
                <Svg image={cartIcon} />
                <StyledQuantity>{cartQuantity}</StyledQuantity>
              </StyledCart>
            </StyledCurrencyCart>
          </StyledBottomInner>
        </Container>
      </StyledBottom>
      <StyledSearch label="Search for" locale={locale} isNavOpen={isNavOpen} />
    </StyledHeader>
  );
};

export default Header;
