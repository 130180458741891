import { css } from 'styled-components';
import {
  minBreakpointQuery,
  fontSize,
  brandColours,
  standardColours,
  standardTransition,
} from '../../styles';

export const labelStyles = () => {
  return css`
    display: block;
    position: relative;
    margin-bottom: 16px;
    grid-column: 1 / 3;

    ${minBreakpointQuery.medium`
      grid-column: auto;
    `}
  `;
};

export const fullLabelStyles = () => {
  return css`
    display: block;
    position: relative;
    grid-column: 1 / 3;
    margin-bottom: 16px;
  `;
};

export const labelTextStyles = () => {
  return css`
    display: block;
    margin-bottom: 6px;
    color: ${standardColours.lightBlack};
    ${fontSize(12)};
  `;
};

export const commonFormElementStyles = () => {
  return css`
    width: 100%;
    height: 46px;
    background-color: ${standardColours.lightestGrey};
    border: none;
    outline: 1px solid ${standardColours.lightestGrey};
    padding: 4px 16px;
    line-height: 1.2;
    transition: ${standardTransition('outline-color')};

    &:focus {
      outline-color: ${brandColours.primary};
    }
  `;
};

export const inputStyles = () => {
  return css`
    ${commonFormElementStyles()};
  `;
};

export const selectWrapStyles = () => {
  return css`
    position: relative;
    display: block;

    &:after {
      content: '';
      position: absolute;
      color: ${brandColours.primary};
      top: 50%;
      right: 20px;
      height: 12px;
      width: 12px;
      border: 2px solid ${standardColours.black};
      border-top: 0;
      border-left: 0;
      pointer-events: none;
      transform: translateY(-10px) rotate(45deg);
    }
  `;
};

export const selectStyles = () => {
  return css`
    ${commonFormElementStyles()};
    padding-right: 40px;
    appearance: none;
    background: none;
  `;
};

export const textAreaStyles = () => {
  return css`
    ${commonFormElementStyles()};
    padding: 26px 26px;
    min-height: 100px;
    resize: vertical;

    ${minBreakpointQuery.mlarge`
      min-height: 160px;
    `}
  `;
};

export const formButtonStyles = () => {
  return css`
    margin-top: 10px;
    ${fontSize(14)};
    background-color: ${brandColours.primary};
    border-radius: 0;
  `;
};

export const formGridStyles = () => {
  return css`
    margin-top: 20px;
    display: grid;
    text-align: left;

    ${minBreakpointQuery.medium`
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
    `}
  `;
};
