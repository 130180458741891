import React from 'react';
import styled from 'styled-components';
import {
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';

const StyledProductQuantity = styled.label``;

const StyledLabel = styled.span`
  ${visuallyHidden()};
`;

const StyledText = styled.span`
  ${visuallyHidden()};
`;

const StyledQuantityWrap = styled.div`
  position: relative;
  border: 1px solid ${standardColours.grey};
  border-radius: 5px;
  overflow: hidden;
`;

const StyledQuantityButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ alt }) => (alt ? 'right: 0' : 'left: 0')};
  padding: ${({ isCartItem }) => (isCartItem ? '5px' : '10px')};
  width: ${({ isCartItem }) => (isCartItem ? '25px' : '38px')};
  background: ${standardColours.lighterGrey};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: ${({ alt }) => (alt ? '"+"' : '"-"')};
    color: ${standardColours.black};
    font-family: ${brandFonts.tertiary};
    ${({ isCartItem }) => (isCartItem ? fontSize('20') : fontSize('24'))};
    line-height: ${({ alt }) => (alt ? '1.1' : '0.9')};
  }
`;

const StyledQuantity = styled.input`
  padding-right: ${({ isCartItem }) => (isCartItem ? '25px' : '38px')};
  padding-left: ${({ isCartItem }) => (isCartItem ? '25px' : '38px')};
  height: ${({ isCartItem }) => (isCartItem ? '34px' : '46px')};
  width: ${({ isCartItem }) => (isCartItem ? '75px' : '120px')};
  font-weight: ${fontWeights.bold};
  ${({ isCartItem }) => (isCartItem ? fontSize('14') : fontSize('16'))};
  text-align: center;
  border: 0;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const ProductQuantity = ({
  quantity,
  isCartItem,
  changeFunction,
  ...props
}) => (
  <StyledProductQuantity htmlFor="product-form-quantity" {...props}>
    <StyledLabel>Quantity</StyledLabel>
    <StyledQuantityWrap>
      <StyledQuantityButton
        type="button"
        value="remove"
        isCartItem={isCartItem}
        onClick={changeFunction}
      >
        <StyledText>Remove One</StyledText>
      </StyledQuantityButton>
      <StyledQuantity
        type="number"
        id="product-form-quantity"
        name="quantity"
        value={quantity}
        min="1"
        required
        isCartItem={isCartItem}
        onChange={changeFunction}
      />
      <StyledQuantityButton
        type="button"
        value="add"
        alt={true}
        isCartItem={isCartItem}
        onClick={changeFunction}
      >
        <StyledText>Add One</StyledText>
      </StyledQuantityButton>
    </StyledQuantityWrap>
  </StyledProductQuantity>
);

export default ProductQuantity;
