import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import * as JsSearch from 'js-search';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
  visuallyHidden,
  maxBreakpointQuery,
} from '../styles';
import { Container, Svg } from './ui';
import { getSingleLocaleFields, buildUrl, translateString } from '../utils';
import searchIcon from '../images/search-icon.inline.svg';
import whatsappIcon from '../images/whatsapp-icon.inline.svg';

const StyledSearch = styled.section`
  ${({ isSearchPage }) => {
    if (!isSearchPage) {
      return css`
        display: flex;
        align-items: center;
      `;
    } else {
      return css`
        padding: 20px 0;

        ${minBreakpointQuery.small`
          padding-top: 25px;
          padding-bottom: 25px;
        `}

        ${minBreakpointQuery.large`
          padding-top: 30px;
          padding-bottom: 30px;
        `}
      `;
    }
  }}
`;

const StyledInner = styled.div`
  ${({ isSearchPage }) => {
    if (!isSearchPage) {
      return css`
        display: flex;
        gap: 16px;
        align-items: center;
      `;
    }
  }}
`;

const StyledForm = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: ${({ isSearchPage }) => (isSearchPage ? '600px' : 'auto')};

  ${({ isSearchPage }) => {
    if (!isSearchPage) {
      return css`
        flex-grow: 1;
      `;
    }
  }}
`;

const StyledWhatsapp = styled.a`
  display: inline-block;

  ${minBreakpointQuery.mlarge`
    display: none;
  `}
`;

const StyledWhatsappIcon = styled(Svg)`
  width: 35px;
  height: 35px;
`;

const StyledIcon = styled(Svg)`
  position: absolute;
  top: 50%;
  left: 14px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
`;

const StyledField = styled.label`
  width: 100%;
`;

const StyledLabel = styled.span`
  ${visuallyHidden()};
`;

const StyledInput = styled.input`
  width: 100%;
  background: none;
  border: none;

  ${({ isSearchPage }) => {
    if (isSearchPage) {
      return css`
        color: ${standardColours.white};
        border-bottom: 1px solid ${standardColours.white};
        ${fontSize(18)};
        height: 50px;
        padding-right: 40px;

        ::-webkit-input-placeholder {
          color: ${standardColours.white};
        }

        ::-moz-placeholder {
          color: ${standardColours.white};
        }

        :-ms-input-placeholder {
          color: ${standardColours.white};
        }

        :-moz-placeholder {
          color: ${standardColours.white};
        }
      `;
    } else {
      return css`
        padding-left: 44px;
        height: 42px;
        font-weight: ${fontWeights.regular};
        ${fontSize(12)};
        background-color: ${standardColours.lighterGrey};

        ::-webkit-input-placeholder {
          color: ${standardColours.lightBlack};
        }

        ::-moz-placeholder {
          color: ${standardColours.lightBlack};
        }

        :-ms-input-placeholder {
          color: ${standardColours.lightBlack};
        }

        :-moz-placeholder {
          color: ${standardColours.lightBlack};
        }

        &:focus {
          ${maxBreakpointQuery.smedium`
            ${fontSize(16)};
          `}
        }
      `;
    }
  }}
`;

const StyledSubmit = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0;
  ${fontSize(32)};
  background: none;
  border: none;
  transform: translateY(-50%);

  ${({ isSearchPage }) => {
    if (isSearchPage) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}

  span {
    ${visuallyHidden()};
  }
`;

const Search = ({
  products,
  productCategories,
  label,
  term,
  setTerm,
  setResults,
  setCategoryResults,
  locale,
  isSearchPage,
  isNavOpen,
  ...props
}) => {
  const {
    allDatoCmsSearch,
    datoCmsContactInfo: { whatsappUrl },
  } = useStaticQuery(graphql`
    query SearchQuery {
      allDatoCmsSearch {
        nodes {
          locale
          slug
        }
      }
      datoCmsContactInfo {
        whatsappUrl
      }
    }
  `);

  const datoCmsSearch = getSingleLocaleFields(allDatoCmsSearch, locale);

  const [search, setSearch] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(products);

  const searchRef = useRef(null);

  useEffect(() => {
    const searchData = async () => {
      if (isSearchPage) {
        const search = new JsSearch.Search('id');
        search.addIndex('title');
        search.addIndex('descriptiveName');
        search.addIndex('description');
        search.addIndex('color');
        search.addDocuments(filteredProducts);
        setSearch(search);

        const categorySearch = new JsSearch.Search('id');
        categorySearch.addIndex('title');
        categorySearch.addIndex('description');
        categorySearch.addDocuments(productCategories);

        if (term.length > 2) {
          let formattedTerm = term;

          // Replace for wall paper with wallpaper
          formattedTerm = term.replace('wall paper', 'wallpaper');

          const results = await search.search(
            formattedTerm.toLowerCase() === 'childrens'
              ? 'children'
              : formattedTerm
          );
          const categoryResults = await categorySearch.search(
            formattedTerm.toLowerCase() === 'childrens'
              ? 'children'
              : formattedTerm
          );

          if (
            formattedTerm.toLowerCase() === 'kids' ||
            formattedTerm.toLowerCase() === 'kid'
          ) {
            const resultsChildren = await search.search('children');
            const categoryResultsChildren = await categorySearch.search(
              'children'
            );
            results.push(...resultsChildren);
            categoryResults.push(...categoryResultsChildren);
          }

          // Exact cool name match move to top
          const coolNameIndex = results.findIndex(
            result => result.title.toLowerCase() === formattedTerm.toLowerCase()
          );

          if (coolNameIndex !== -1) {
            results.unshift(results.splice(coolNameIndex, 1)[0]);
          }

          setResults(results && results.length > 0 ? results : null);
          setCategoryResults(
            categoryResults && categoryResults.length > 0
              ? categoryResults
              : null
          );
        } else {
          setResults(undefined);
          setCategoryResults(undefined);
        }
      }
    };

    searchData();
  }, [
    isSearchPage,
    filteredProducts,
    productCategories,
    setResults,
    setCategoryResults,
    term,
  ]);

  useEffect(() => {
    products &&
      products.length > 0 &&
      setFilteredProducts(
        products.map(product => {
          product.color = product.options.filter(
            option => option.name === 'Color'
          )[0].values;
          product.descriptiveName = product.metafields.filter(
            metafield => metafield.key === 'descriptive_name'
          )[0].value;

          return product;
        })
      );
  }, [products]);

  const searchItems = term => {
    if (isSearchPage) {
      setTerm(term);
    }
  };

  const handleSubmit = e => {
    if (isSearchPage) {
      e.preventDefault();
    }
  };

  return (
    <StyledSearch isSearchPage={isSearchPage} isNavOpen={isNavOpen} {...props}>
      <Container>
        <StyledInner isSearchPage={isSearchPage}>
          <StyledForm
            action={buildUrl(datoCmsSearch.slug, datoCmsSearch)}
            method="get"
            autoComplete="off"
            isSearchPage={isSearchPage}
            onSubmit={e => handleSubmit(e)}
          >
            {!isSearchPage && <StyledIcon image={searchIcon} />}
            <StyledField>
              <StyledLabel>{label}</StyledLabel>
              <StyledInput
                type="search"
                name="term"
                ref={searchRef}
                placeholder={
                  isSearchPage
                    ? translateString('search.page', locale)
                    : `${translateString('search.box', locale)}...`
                }
                defaultValue={term}
                isSearchPage={isSearchPage}
                onChange={e => searchItems(e.target.value)}
              />
            </StyledField>
            {isSearchPage && (
              <StyledSubmit isSearchPage={isSearchPage}>
                <span>Submit</span> →
              </StyledSubmit>
            )}
          </StyledForm>
          {!isSearchPage && (
            <StyledWhatsapp
              href={whatsappUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Message on WhatsApp"
            >
              <StyledWhatsappIcon image={whatsappIcon} />
            </StyledWhatsapp>
          )}
        </StyledInner>
      </Container>
    </StyledSearch>
  );
};

export default Search;
