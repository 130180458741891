import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  standardColours,
  fontSize,
} from '../styles';
import FreeDeliveryMessage from './FreeDeliveryMessage';
import { getSingleLocaleFields } from '../utils';
import deliveryIcon from '../images/delivery-icon.svg';

const StyledSitewideNotice = styled.div`
  text-align: center;
`;

const StyledItems = styled.div`
  display: flex;
  height: ${({ isLoaded }) => (isLoaded ? 'auto' : '25px')};
  min-height: 25px;

  ${minBreakpointQuery.mlarge`
    gap: 24px;
  `}
`;

const StyledItem = styled.div`
  display: ${({ isLoaded }) => (isLoaded ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  min-height: 25px;

  ${minBreakpointQuery.xxlarge`
    display: flex;
  `}
`;

const StyledText = styled.div`
  p {
    color: ${standardColours.lightBlack};
    ${fontSize(12)};

    a {
      text-decoration: underline;
    }
  }
`;

const StyledIcon = styled.img`
  margin-left: 6px;
  height: 14px;
  width: 14px;
`;

const StyleFreeDeliveryMessage = styled(FreeDeliveryMessage)`
  p {
    color: ${standardColours.lightBlack};
    ${fontSize(12)};

    a {
      text-decoration: underline;
    }
  }
`;

const StyledBadge = styled.img`
  display: block;
  margin: auto;
  width: 142px;
`;

const SitewideNotice = ({ locale }) => {
  const { allDatoCmsHeader } = useStaticQuery(graphql`
    query SitewideNoticeQuery {
      allDatoCmsHeader {
        nodes {
          locale
          sitewideNotices {
            id
            sitewideNotice
            icon {
              url
              alt
            }
          }
          badge {
            url
            alt
          }
        }
      }
    }
  `);

  const { sitewideNotices, badge } = getSingleLocaleFields(
    allDatoCmsHeader,
    locale
  );

  const [isLoaded, setLoaded] = useState(false);

  const [sliderRef] = useKeenSlider(
    {
      slides: { perView: 1 },
      initial: 0,
      loop: true,
      breakpoints: {
        [`(min-width: ${breakpointSizes.mlarge}px)`]: {
          disabled: true,
        },
      },
    },
    [
      slider => {
        let timeout;
        let mouseOver = false;

        function clearNextTimeout() {
          clearTimeout(timeout);
        }

        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 4000);
        }

        slider.on('created', () => {
          setTimeout(() => {
            setLoaded(true);
            slider.update();
          }, 2000);

          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
        });

        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ]
  );

  return (
    (badge || sitewideNotices.length > 0) && (
      <StyledSitewideNotice>
        <StyledItems ref={sliderRef} className="keen-slider">
          {sitewideNotices.map(({ id, sitewideNotice, icon }) => (
            <StyledItem
              key={id}
              className="keen-slider__slide"
              isLoaded={isLoaded}
            >
              <StyledText
                dangerouslySetInnerHTML={{ __html: sitewideNotice }}
              />
              <StyledIcon src={icon.url} alt={icon.alt} loading="lazy" />
            </StyledItem>
          ))}
          <StyledItem className="keen-slider__slide" isLoaded={isLoaded}>
            <StyleFreeDeliveryMessage locale={locale} />
            <StyledIcon src={deliveryIcon} alt="Delivery" loading="lazy" />
          </StyledItem>
          {badge && (
            <StyledItem className="keen-slider__slide" isLoaded={true}>
              <StyledBadge src={badge.url} alt={badge.alt} loading="lazy" />
            </StyledItem>
          )}
        </StyledItems>
      </StyledSitewideNotice>
    )
  );
};

export default SitewideNotice;
