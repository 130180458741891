import React from 'react';
import styled from 'styled-components';
import { breakpointSizes, minBreakpointQuery } from '../../styles';

const StyledContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: ${({ narrow, wide }) =>
    narrow
      ? breakpointSizes.large
      : wide
      ? breakpointSizes.xxxxlarge
      : breakpointSizes.xlarge}px;

  ${minBreakpointQuery.smedium`
    padding-right: 30px;
    padding-left: 30px;
  `}
`;

export const Container = ({ narrow, children, ...props }) => (
  <StyledContainer narrow={narrow} {...props}>
    {children}
  </StyledContainer>
);
