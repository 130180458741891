import { createGlobalStyle, css } from 'styled-components';
import {
  maxBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';
import butlerExtraBoldWoff2 from '../fonts/Butler-ExtraBold.woff2';
import butlerExtraBoldWoff from '../fonts/Butler-ExtraBold.woff';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Butler';
    src: url(${butlerExtraBoldWoff2}) format('woff2'),
        url(${butlerExtraBoldWoff}) format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    color: ${standardColours.black};
    font-family: ${brandFonts.primary};
    font-weight: ${fontWeights.medium};
    ${fontSize(16)}
    line-height: 1.3;

    ${({ isNavOpen }) => {
      if (isNavOpen) {
        return css`
          ${maxBreakpointQuery.mlarge`
            overflow: hidden;
          `}
        `;
      }
    }};

    ${({ showHubspotLiveChat }) => {
      if (!showHubspotLiveChat) {
        return css`
          #hubspot-messages-iframe-container {
            display: none !important;
          }
        `;
      }
    }}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  img {
    height: auto;
    width: auto;
    vertical-align: middle;
    max-width: 100%;
    border: 0;
    -ms-interpolation-mode: bicubic;
  }
  
  button,
  input,
  select,
  textarea {
    color: ${standardColours.black};
    font-family: ${brandFonts.primary};
    font-weight: ${fontWeights.regular};
    ${fontSize(16)}
    line-height: 1.3;
  }

  button {
    cursor: pointer;
  }

  input {

    ::-webkit-input-placeholder {
      color: ${standardColours.black};
    }

    ::-moz-placeholder {
      color: ${standardColours.black};
    }

    :-ms-input-placeholder {
      color: ${standardColours.black};
    }

    :-moz-placeholder {
      color: ${standardColours.black};
    }
  }
`;

export default GlobalStyle;
