import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import { Link } from './ui';
import logo from '../images/logo.svg';
import logoAlt from '../images/logo-alt.svg';

const StyledLogo = styled(Link)`
  display: inline-block;
`;

const StyledImage = styled.img`
  width: 110px;

  ${minBreakpointQuery.xtiny`
    width: 120px;
  `}

  ${minBreakpointQuery.tiny`
    width: 130px;
  `}

  ${minBreakpointQuery.small`
    width: 140px;
  `}

  ${minBreakpointQuery.smedium`
    width: 150px;
  `}

  ${minBreakpointQuery.xlarge`
    width: 160px;
  `}

  ${minBreakpointQuery.xxlarge`
    width: 180px;
  `}

  ${minBreakpointQuery.xxxlarge`
    width: 190px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    width: 200px;
  `}
`;

const Logo = ({ alt, locale, ...props }) => {
  const {
    datoCmsSite: {
      globalSeo: { siteName },
    },
  } = useStaticQuery(graphql`
    query LogoQuery {
      datoCmsSite {
        globalSeo {
          siteName
        }
      }
    }
  `);

  return (
    <StyledLogo to={locale === 'en' ? '/' : locale} {...props}>
      <StyledImage src={alt ? logoAlt : logo} alt={siteName} loading="lazy" />
    </StyledLogo>
  );
};

export default Logo;
