import React, { useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import GlobalStyle from '../styles/GlobalStyle';
import { Wrapper } from './ui';
import Header from './Header';
import LocationSettings from './LocationSettings';
import Cart from './Cart';
import Footer from './Footer';
import CookieNotice from './CookieNotice';
import { buildUrl } from '../utils';
import { StoreContext } from '../context/StoreContext';

const Layout = ({ seo, locale, slugLocales, page, noIndex, children }) => {
  const {
    datoCmsSite: { faviconMetaTags },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
    }
  `);

  const {
    isOverlayActive,
    setOverlayActive,
    isLocationSettingsOpen,
    toggleLocationSettingsOpen,
    isCartOpen,
    toggleCartOpen,
  } = useContext(StoreContext);

  const [isNavOpen, setNavOpen] = useState(false);
  const [showHubspotLiveChat, setShowHubspotLiveChat] = useState(false);

  useEffect(() => {
    setOverlayActive(isLocationSettingsOpen || isCartOpen);
  }, [setOverlayActive, isNavOpen, isLocationSettingsOpen, isCartOpen]);

  useEffect(() => {
    function onScroll() {
      !showHubspotLiveChat && setShowHubspotLiveChat(true);
    }
    window.addEventListener('scroll', onScroll);

    if (showHubspotLiveChat) {
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [showHubspotLiveChat]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'optimize.activate' });
  }, []);

  return (
    <>
      <HelmetDatoCms seo={seo} favicon={faviconMetaTags}>
        <html lang={locale} />
        {slugLocales &&
          slugLocales.length > 0 &&
          []
            .concat(
              {
                ...slugLocales.find(({ locale }) => locale === 'en'),
                isDefault: true,
              },
              slugLocales
            )
            .map(
              ({ isDefault, locale, value }, id) =>
                value && (
                  <link
                    key={id}
                    rel="alternate"
                    hreflang={isDefault ? 'x-default' : locale}
                    href={`${process.env.GATSBY_SITE_URL}${buildUrl(value, {
                      ...page,
                      locale: locale,
                    })}`}
                  />
                )
            )}
        {noIndex && <meta name="robots" content="noindex, follow" />}
        {process.env.GATSBY_GOOGLE_OPTIMIZE_ID && (
          <script
            src={`https://www.googleoptimize.com/optimize.js?id=${process.env.GATSBY_GOOGLE_OPTIMIZE_ID}`}
          ></script>
        )}
        <meta
          name="facebook-domain-verification"
          content="z919s61ljwbmyz0x11ie4eom2t5dye"
        />
        <script>{`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"149006577"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}</script>
      </HelmetDatoCms>
      <GlobalStyle
        isNavOpen={isNavOpen}
        showHubspotLiveChat={showHubspotLiveChat}
      />
      <Wrapper
        isOverlayActive={isOverlayActive}
        onClick={e => {
          if (e.target === e.currentTarget) {
            if (isLocationSettingsOpen) {
              toggleLocationSettingsOpen();
            } else if (isCartOpen) {
              toggleCartOpen();
            }
          }
        }}
      >
        <Header
          locale={locale}
          isNavOpen={isNavOpen}
          setNavOpen={setNavOpen}
          setOverlayActive={setOverlayActive}
        />
        {children}
        <LocationSettings
          locale={locale}
          slugLocales={slugLocales}
          page={page}
        />
        <Cart locale={locale} />
        <Footer locale={locale} />
      </Wrapper>
      <CookieNotice locale={locale} />
    </>
  );
};

export default Layout;
