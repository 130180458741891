import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, standardColours, fontSize } from '../styles';
import { Link } from './ui';
import { buildUrl } from '../utils';

const StyledBreadcrumbs = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledList = styled.ol`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
`;

const StyledItem = styled.li`
  display: flex;
  align-items: center;
  ${fontSize(12)};
  white-space: nowrap;

  ${minBreakpointQuery.small`
    ${fontSize(13)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(14)};
  `}

  ${({ alt }) => {
    if (!alt) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}
`;

const StyledLink = styled(Link)`
  opacity: 0.5;

  &:after {
    content: '/';
    margin-left: 5px;
  }
`;

const Breadcrumbs = ({ currentPageTitle, items = [], alt, ...props }) => {
  items.unshift({ text: 'Home', urlPath: '/' });
  items.push({ text: currentPageTitle });

  return (
    items && (
      <>
        <Helmet
          script={[
            {
              type: 'application/ld+json',
              innerHTML: `{
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  ${items.map(({ text, urlPath }, i) =>
                    JSON.stringify({
                      '@type': 'ListItem',
                      position: i + 1,
                      name: text,
                      ...(urlPath && {
                        item: process.env.GATSBY_SITE_URL + buildUrl(urlPath),
                      }),
                    })
                  )}
                ]
              }`,
            },
          ]}
        />
        <StyledBreadcrumbs {...props}>
          <StyledList>
            {items.map(({ text, urlPath }, i) => (
              <StyledItem key={`breadcrumb-${i}`} alt={alt}>
                {urlPath ? <StyledLink to={urlPath}>{text}</StyledLink> : text}
              </StyledItem>
            ))}
          </StyledList>
        </StyledBreadcrumbs>
      </>
    )
  );
};

export default Breadcrumbs;
