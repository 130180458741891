import React from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';
import { buildUrl } from '../../utils';

const commonButtonStyles = () => {
  return css`
    display: inline-block;
  `;
};

const StyledLink = styled(GatsbyLink)`
  ${commonButtonStyles()};
`;

const StyledLinkExternal = styled.a`
  ${commonButtonStyles()};
`;

const StyledLinkButton = styled.button`
  ${commonButtonStyles()};
  padding: 0;
  background: none;
  border: none;
`;

export const Link = ({ to, children, ...props }) => {
  const LinkComponent = to
    ? StyledLink
    : props.href
    ? StyledLinkExternal
    : StyledLinkButton;

  const slug = typeof to === 'string' ? to : to && to.slug;

  return (
    <LinkComponent
      {...(slug && {
        to: slug === '/' ? slug : buildUrl(slug, to),
      })}
      {...props}
    >
      {children}
    </LinkComponent>
  );
};

export const LinkFragment = graphql`
  fragment LinkFragment on Node {
    ... on DatoCmsHome {
      locale
      slug
    }
    ... on DatoCmsPage {
      locale
      slug
    }
    ... on DatoCmsAllProduct {
      locale
      slug
    }
    ... on DatoCmsProductCategory {
      locale
      slug
      internal {
        type
      }
    }
    ... on DatoCmsProduct {
      locale
      slug
      internal {
        type
      }
    }
    ... on DatoCmsProject {
      locale
      slug
      internal {
        type
      }
    }
    ... on DatoCmsCustomerPhotosArchive {
      locale
      slug
    }
  }
`;
