import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
  standardTransition,
} from '../styles';
import { Link, Container, Button, Svg } from './ui';
import Search from './Search';
import { getSingleLocaleFields } from '../utils';
import mailIcon from '../images/mail-icon.inline.svg';
import phoneIcon from '../images/phone-icon.inline.svg';

const StyledNavigation = styled.nav`
  ${maxBreakpointQuery.mlarge`
    position: fixed;
    top: 186px;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: ${standardColours.white};
    transform: translateX(${({ isNavOpen }) => (isNavOpen ? '0' : '-100%')});
    transition: ${standardTransition('transform')};
  `}
`;

const StyledInner = styled.div`
  ${maxBreakpointQuery.mlarge`
    position: relative;
    padding: 20px 30px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  `}

  ${maxBreakpointQuery.smedium`
    padding: 12px 15px;
  `}
`;

const StyledFeatured = styled.div`
  display: grid;
  gap: 12px;

  ${maxBreakpointQuery.mlarge`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${({ isCollections }) => {
    if (isCollections) {
      return css`
        ${maxBreakpointQuery.mlarge`
          ${visuallyHidden()};
        `}
      `;
    } else {
      return css`
        ${maxBreakpointQuery.mlarge`
          margin-bottom: 32px;
        `}

        ${maxBreakpointQuery.smedium`
          margin-bottom: 26px;
        `}

        ${minBreakpointQuery.mlarge`
          ${visuallyHidden()};
        `}
      `;
    }
  }}
`;

const StyledFeaturedLink = styled(Link)`
  display: block;
  padding: 10px;
  color: ${standardColours.darkerGrey};
  ${fontSize(14)};
  text-align: center;
  border: 1px solid ${standardColours.darkerGrey};

  ${minBreakpointQuery.mlarge`
    transition: ${standardTransition('color')},
      ${standardTransition('background-color')}, ${standardTransition(
    'border-color'
  )};
  `}

  &:hover {
    ${minBreakpointQuery.mlarge`
      color: ${standardColours.lightBlack};
      background-color: ${brandColours.senary};
      border-color: ${brandColours.senary};
    `}
  }
`;

const StyledList = styled.ul``;

// moved up for hover
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  ${maxBreakpointQuery.mlarge`
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    color: ${standardColours.lightBlack};
    font-weight: ${fontWeights.semibold};
    ${fontSize(16)};
    text-align: left;
  `}

  ${maxBreakpointQuery.smedium`
    ${fontSize(14)};
  `}

  ${minBreakpointQuery.mlarge`
    padding-top: 26px;
    padding-bottom: 26px;
    font-weight: ${fontWeights.medium};
    ${fontSize(14)};
    transition: ${standardTransition('color')};
  `}

  ${({ hasChildren }) => {
    if (hasChildren) {
      return css`
        position: relative;
        padding-right: 15px;

        &:after {
          content: ${({ isContact, showSubNav }) =>
            isContact ? "''" : showSubNav ? "'-'" : "'+'"};
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          font-weight: ${fontWeights.regular};
          ${fontSize(20)};
          transform: translateY(-50%);

          ${minBreakpointQuery.smedium`
            ${fontSize(22)};
          `}

          ${minBreakpointQuery.mlarge`
            content: '';
            height: 5px;
            width: 5px;
            border: 1px solid ${standardColours.black};
            border-bottom: 0;
            border-left: 0;
            border-radius: 1px;
            transform: translate(-50%, -50%) rotate(135deg);
            transition: ${standardTransition('border-color')};
          `}
        }
      `;
    }
  }}
`;

// moved up for hover
const StyledSubNav = styled.div`
  ${maxBreakpointQuery.mlarge`
    display: ${({ showSubNav }) => (showSubNav ? 'block' : 'none')};
    padding-right: 15px;
    padding-left: 15px;
  `}

  ${maxBreakpointQuery.smedium`
    padding-right: 10px;
    padding-left: 10px;
  `}

  ${({ isCollectionsItem }) => {
    if (!isCollectionsItem) {
      return css`
        ${minBreakpointQuery.mlarge`
          position: absolute;
          left: -9999em;
          z-index: 1;
          padding-top: 34px;
          padding-bottom: 34px;
          width: 100%;
          background-color: ${standardColours.white};
          border-top: 1px solid ${standardColours.lightGrey};
          border-bottom: 1px solid ${standardColours.lightGrey};
        `}
      `;
    }
  }}
`;

// moved up for hover
const StyledContactInfo = styled.div`
  ${maxBreakpointQuery.mlarge`
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid ${standardColours.lightGrey};
  `}

  ${maxBreakpointQuery.smedium`
    margin-top: 25px;
    padding-top: 25px;
  `}

  ${minBreakpointQuery.mlarge`
    position: absolute;
    left: -9999em;
    z-index: 1;
    transform: translateX(-50%);
  `}

  &:before {
    ${minBreakpointQuery.mlarge`
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 9px;
      width: 9px;
      background-color: ${standardColours.white};
      border-radius: 2px;
      transform: translate(-50%, 50%) rotate(45deg);
    `}
  }
`;

const StyledItem = styled.li`
  ${maxBreakpointQuery.mlarge`
    margin-top: 18px;
  `}

  ${maxBreakpointQuery.smedium`
    margin-top: 15px;
  `}

  ${minBreakpointQuery.mlarge`
    display: inline-block;
    margin-left: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 24px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-left: 28px;
  `}

  &:first-child {
    ${maxBreakpointQuery.mlarge`
      margin-top: 0;
    `}

    ${minBreakpointQuery.mlarge`
      margin-left: 0;
    `}
  }

  &:hover {
    ${StyledLink} {
      ${minBreakpointQuery.mlarge`
        color: ${brandColours.primary};
      `}

      &:after {
        ${minBreakpointQuery.mlarge`
          border-color: ${brandColours.primary};
        `}
      }
    }

    ${StyledSubNav} {
      ${minBreakpointQuery.mlarge`
        left: 0;
      `}
    }
  }

  ${({ isContact }) => {
    if (isContact) {
      return css`
        ${minBreakpointQuery.mlarge`
          position: relative;
        `}

        &:hover {
          ${StyledContactInfo} {
            ${minBreakpointQuery.mlarge`
              left: 50%;
            `}
          }
        }
      `;
    }
  }}
`;

const StyledIcon = styled.img`
  margin-left: 10px;
  height: 14px;
  width: 14px;

  ${minBreakpointQuery.smedium`
    height: 16px;
    width: 16px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-left: 8px;
    height: 12px;
    width: 12px;
  `}

  ${minBreakpointQuery.large`
    height: 14px;
    width: 14px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-left: 10px;
    height: 16px;
    width: 16px;
  `}
`;

const StyledSubInner = styled.div`
  ${minBreakpointQuery.mlarge`  
    display: grid;  
  `}

  ${({ isCollections }) => {
    if (isCollections) {
      return css`
        ${minBreakpointQuery.mlarge`
          align-items: start;
          grid-template-columns: 1.2fr 2.8fr repeat(2, 1fr);
          gap: 80px;
        `}

        ${minBreakpointQuery.large`
          gap: 100px
        `}
      `;
    } else {
      return css`
        ${minBreakpointQuery.mlarge`
          grid-template-columns: 1.25fr repeat(3,1fr);
          gap: 24px;
        `}
      `;
    }
  }}
`;

const StyledSubTextList = styled.div`
  ${({ isCollectionsItem }) => {
    if (isCollectionsItem) {
      return css`
        ${maxBreakpointQuery.mlarge`
          margin-top: 8px;
        `}

        ${maxBreakpointQuery.smedium`
          margin-top: 6px;
        `}

        &:first-child {
          ${maxBreakpointQuery.mlarge`
            margin-top: 0;
          `}

          ${maxBreakpointQuery.smedium`
            margin-top: 0;
          `}
        }
      `;
    }
  }}
`;

const StyledSubText = styled.p`
  ${({ isCollectionsItem }) => {
    if (isCollectionsItem) {
      return css`
        ${maxBreakpointQuery.mlarge`
          position: relative;
          display: flex;
          align-items: center;
          padding-top: 5px;
          padding-right: 15px;
          padding-bottom: 5px;
          color: ${standardColours.darkestGrey};
          ${fontSize(16)};
          cursor: pointer;
        `}

        ${maxBreakpointQuery.smedium`
          ${fontSize(14)};
        `}

        &:after {
          ${maxBreakpointQuery.mlarge`
            content: ${({ showSubNav }) => (showSubNav ? "'-'" : "'+'")};
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            font-weight: ${fontWeights.regular};
            ${fontSize(20)};
            transform: translateY(-50%);
          `}

          ${maxBreakpointQuery.smedium`
            ${fontSize(18)};
          `}
        }
      `;
    } else {
      return css`
        ${maxBreakpointQuery.mlarge`
          ${visuallyHidden()};
        `}
      `;
    }
  }}

  ${minBreakpointQuery.mlarge`
    color: ${standardColours.lightBlack};
    font-weight: ${fontWeights.semibold};
    ${fontSize(14)};
  `}
`;

const StyledSubList = styled.ul`
  ${({ isCollectionsItem }) => {
    if (isCollectionsItem) {
      return css`
        ${maxBreakpointQuery.mlarge`
          padding: 12px 15px;
        `}

        ${maxBreakpointQuery.smedium`
          padding: 8px 10px;
        `}
      `;
    } else {
      return css`
        margin-top: 5px;
      `;
    }
  }}

  ${minBreakpointQuery.mlarge`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-top: 10px;
    max-height: 270px;
  `}
`;

const StyledSubItem = styled.li`
  ${maxBreakpointQuery.mlarge`
    margin-top: 8px;
  `}

  ${maxBreakpointQuery.smedium`
    margin-top: 6px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 3px;
  `}  

  ${minBreakpointQuery.large`
    margin-top: 5px;
  `}

  &:first-child {
    ${maxBreakpointQuery.mlarge`
      margin-top: 0;
    `}
  }
`;

const StyledSubLink = styled(Link)`
  padding-top: 5px;
  padding-bottom: 5px;
  color: ${standardColours.darkerGrey};

  ${maxBreakpointQuery.mlarge`
    width: 100%;
    ${fontSize(16)};
    text-align: left;
  `}

  ${maxBreakpointQuery.smedium`
    ${fontSize(14)};
  `}

  ${minBreakpointQuery.mlarge`
    font-weight: ${fontWeights.regular};
    ${fontSize(14)};
    transition: ${standardTransition('color')};
  `}

  &:hover {
    ${minBreakpointQuery.mlarge`
      color: ${brandColours.primary};
    `}
  }
`;

const StyledCta = styled(Link)`
  ${maxBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}
`;

const StyledCtaContent = styled.div`
  ${minBreakpointQuery.mlarge`
    margin-top: 18px;
    text-align: center;
  `}
`;

const StyledCtaOverline = styled.p`
  ${minBreakpointQuery.mlarge`
    color: ${standardColours.darkerGrey};
    ${fontSize(12)};
    letter-spacing: 0.06em;
    text-transform: uppercase;
  `}
`;

const StyledCtaText = styled.p`
  ${minBreakpointQuery.mlarge`
    margin-top: 4px;
    color: ${standardColours.lightBlack};
    font-weight: ${fontWeights.semibold};
  `}
`;

const StyledSearch = styled(Search)`
  ${maxBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 24px;
  `}
`;

const StyledContactInfoInner = styled.div`
  text-align: center;

  ${maxBreakpointQuery.mlarge`
    display: flex;
    flex-direction: column;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 8px;
    padding: 30px;
    width: 320px;
    background-color: ${standardColours.white};
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 ${standardColours.transparentBlack(0.14)};
  `}
`;

const StyledContactInfoHeading = styled.p`
  margin-bottom: 10px;
  color: ${standardColours.lightBlack};
  ${fontSize(14)};

  ${minBreakpointQuery.mlarge`
    color: ${brandColours.primary};
    ${fontSize(18)};
  `}

  strong {
    ${minBreakpointQuery.mlarge`
      font-weight: ${fontWeights.semibold};
    `}
  }
`;

const StyledContactInfoText = styled.p`
  ${maxBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.mlarge`
    margin: 10px;
    font-weight: ${fontWeights.regular};
    ${fontSize(12)};
    line-height: 1.4;
  `}
`;

const StyledContactInfoButton = styled(Button)`
  margin-top: 4px;

  ${maxBreakpointQuery.mlarge`
    padding: 4px;
    color: ${standardColours.lightBlack};
    font-weight: ${fontWeights.medium};
    ${fontSize(14)};
    background: none;
    border: none;
  `}

  ${minBreakpointQuery.mlarge`
    width: 100%;
  `}

  svg {
    margin-right: 8px;
    height: 15px;
    width: 15px;
    vertical-align: middle;
  }

  ${({ alt }) => {
    if (alt) {
      return css`
        ${minBreakpointQuery.mlarge`
          color: ${standardColours.black};
          font-weight: ${fontWeights.regular};
          border-color: ${standardColours.black};
        `}

        &:hover {
          ${minBreakpointQuery.mlarge`
            color: ${standardColours.white};
            background-color: ${standardColours.black};
          `}
        }
      `;
    }
  }}
`;

const CollectionsItem = ({ name, items }) => {
  const [showSubNav, setShowSubNav] = useState(false);

  return (
    <StyledSubTextList isCollectionsItem={true}>
      <StyledSubText
        isCollectionsItem={true}
        showSubNav={showSubNav}
        onClick={() => setShowSubNav(!showSubNav)}
      >
        {name}
      </StyledSubText>
      <StyledSubNav isCollectionsItem={true} showSubNav={showSubNav}>
        <StyledSubList isCollectionsItem={true}>
          {items.map((item, id) => (
            <StyledSubItem key={id}>
              <StyledSubLink to={item}>
                {item.text}
                {item.icon && (
                  <StyledIcon
                    src={item.icon.url}
                    alt={item.icon.alt}
                    loading="lazy"
                  />
                )}
              </StyledSubLink>
            </StyledSubItem>
          ))}
        </StyledSubList>
      </StyledSubNav>
    </StyledSubTextList>
  );
};

const Collections = ({
  heading,
  featured,
  items,
  locale,
  isNavOpen,
  setOverlayActive,
}) => {
  const [showSubNav, setShowSubNav] = useState(false);

  return (
    <StyledItem
      onMouseOver={() => setOverlayActive('Navigation')}
      onMouseOut={() => setOverlayActive(false)}
    >
      <StyledLink
        hasChildren={true}
        showSubNav={showSubNav}
        onClick={() => setShowSubNav(!showSubNav)}
      >
        {heading}
      </StyledLink>
      <StyledSubNav showSubNav={showSubNav}>
        <Container>
          <StyledSubInner isCollections={true}>
            <StyledFeatured isCollections={true}>
              {featured.map((link, id) => (
                <StyledFeaturedLink key={id} to={link}>
                  {link.text}
                </StyledFeaturedLink>
              ))}
            </StyledFeatured>
            {items.map(({ name, items }, id) => (
              <CollectionsItem key={id} name={name} items={items} />
            ))}
          </StyledSubInner>
        </Container>
        <StyledSearch
          label="Search for"
          locale={locale}
          isNavOpen={isNavOpen}
        />
      </StyledSubNav>
    </StyledItem>
  );
};

const LinkList = ({
  name,
  items,
  ctas,
  locale,
  isNavOpen,
  setOverlayActive,
}) => {
  const [showSubNav, setShowSubNav] = useState(false);

  return (
    <StyledItem
      onMouseOver={() => setOverlayActive('Navigation')}
      onMouseOut={() => setOverlayActive(false)}
    >
      <StyledLink
        hasChildren={true}
        showSubNav={showSubNav}
        onClick={() => setShowSubNav(!showSubNav)}
      >
        {name}
      </StyledLink>
      <StyledSubNav showSubNav={showSubNav}>
        <Container>
          <StyledSubInner>
            <StyledSubTextList>
              <StyledSubText>{name}</StyledSubText>
              <StyledSubList>
                {items.map(({ link }, id) => (
                  <StyledSubItem key={id}>
                    <StyledSubLink to={link.page ? link.page : link}>
                      {link.text}
                    </StyledSubLink>
                  </StyledSubItem>
                ))}
              </StyledSubList>
            </StyledSubTextList>
            {ctas.map(({ image, overline, link }, id) => (
              <StyledCta key={id} to={link.page ? link.page : link}>
                <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
                <StyledCtaContent>
                  <StyledCtaOverline>{overline}</StyledCtaOverline>
                  <StyledCtaText>{link.text}</StyledCtaText>
                </StyledCtaContent>
              </StyledCta>
            ))}
          </StyledSubInner>
        </Container>
        <StyledSearch
          label="Search for"
          locale={locale}
          isNavOpen={isNavOpen}
        />
      </StyledSubNav>
    </StyledItem>
  );
};

const Navigation = ({ locale, isNavOpen, setOverlayActive, ...props }) => {
  const { allDatoCmsNavigation, allDatoCmsContactInfo } =
    useStaticQuery(graphql`
      query NavigationQuery {
        allDatoCmsNavigation {
          nodes {
            locale
            featuredMobile {
              text: title
              ...LinkFragment
            }
            featuredDesktop {
              text: title
              ...LinkFragment
            }
            collectionsHeading
            categoriesHeading
            categories {
              text: title
              icon: navigationIcon {
                url
                alt
              }
              ...LinkFragment
            }
            stylesHeading
            styles {
              text: title
              icon: navigationIcon {
                url
                alt
              }
              ...LinkFragment
            }
            collabsHeading
            collabs {
              text: title
              icon: navigationIcon {
                url
                alt
              }
              ...LinkFragment
            }
            linkLists {
              name
              navigationLinks {
                link {
                  ... on DatoCmsLink {
                    text
                    page {
                      ...LinkFragment
                    }
                  }
                  ... on DatoCmsProductCategory {
                    text: title
                    ...LinkFragment
                  }
                }
              }
              navigationCtas {
                image {
                  gatsbyImageData(width: 290, height: 175)
                  alt
                }
                overline
                link {
                  ... on DatoCmsLink {
                    text
                    page {
                      ...LinkFragment
                    }
                  }
                  ... on DatoCmsProductCategory {
                    text: title
                    ...LinkFragment
                  }
                }
              }
            }
            pageLinks {
              link {
                text
                page {
                  ...LinkFragment
                }
              }
              icon {
                url
                alt
              }
            }
            contactPageLink {
              text
              page {
                ...LinkFragment
              }
            }
            contactInfoHeading
            contactInfoText
          }
        }
        allDatoCmsContactInfo {
          nodes {
            locale
            phoneNumber
            emailAddress
          }
        }
      }
    `);

  const {
    featuredMobile,
    featuredDesktop,
    collectionsHeading,
    categoriesHeading,
    categories,
    stylesHeading,
    styles,
    collabsHeading,
    collabs,
    linkLists,
    pageLinks,
    contactPageLink,
    contactInfoHeading,
    contactInfoText,
  } = getSingleLocaleFields(allDatoCmsNavigation, locale);

  const { phoneNumber, emailAddress } = getSingleLocaleFields(
    allDatoCmsContactInfo,
    locale
  );

  return (
    <StyledNavigation isNavOpen={isNavOpen} {...props}>
      <StyledInner>
        <StyledFeatured>
          {featuredMobile.map((link, id) => (
            <StyledFeaturedLink key={id} to={link}>
              {link.text}
            </StyledFeaturedLink>
          ))}
        </StyledFeatured>
        <StyledList>
          <Collections
            heading={collectionsHeading}
            featured={featuredDesktop}
            items={[
              {
                name: categoriesHeading,
                items: categories,
              },
              {
                name: stylesHeading,
                items: styles,
              },
              {
                name: collabsHeading,
                items: collabs,
              },
            ]}
            locale={locale}
            isNavOpen={isNavOpen}
            setOverlayActive={setOverlayActive}
          />
          {linkLists.map(({ name, navigationLinks, navigationCtas }, id) => (
            <LinkList
              key={id}
              name={name}
              items={navigationLinks}
              ctas={navigationCtas}
              locale={locale}
              isNavOpen={isNavOpen}
              setOverlayActive={setOverlayActive}
            />
          ))}
          {pageLinks.map(({ link: { text, page }, icon }, id) => (
            <StyledItem key={id}>
              <StyledLink to={page}>
                {text}
                {icon && (
                  <StyledIcon src={icon.url} alt={icon.alt} loading="lazy" />
                )}
              </StyledLink>
            </StyledItem>
          ))}
          <StyledItem isContact={true}>
            <StyledLink
              to={contactPageLink.page}
              hasChildren={true}
              isContact={true}
            >
              {contactPageLink.text}
            </StyledLink>
            <StyledContactInfo>
              <StyledContactInfoInner>
                <StyledContactInfoHeading>
                  <strong>{contactInfoHeading}</strong>
                </StyledContactInfoHeading>
                {contactInfoText && (
                  <StyledContactInfoText>
                    {contactInfoText}
                  </StyledContactInfoText>
                )}
                <StyledContactInfoButton href={`tel:${phoneNumber}`}>
                  <Svg image={phoneIcon} />
                  Tel: {phoneNumber}
                </StyledContactInfoButton>
                <StyledContactInfoButton
                  href={`mailto:${emailAddress}`}
                  alt={true}
                >
                  <Svg image={mailIcon} />
                  {emailAddress}
                </StyledContactInfoButton>
                <StyledContactInfoButton
                  alt={true}
                  onClick={() => window.HubSpotConversations.widget.open()}
                >
                  Live Chat
                </StyledContactInfoButton>
              </StyledContactInfoInner>
            </StyledContactInfo>
          </StyledItem>
        </StyledList>
      </StyledInner>
    </StyledNavigation>
  );
};

export default Navigation;
