import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  zIndexLayers,
  visuallyHidden,
  standardTransition,
} from '../styles';
import { translateString } from '../utils';

const StyledPopOutSidebar = styled.aside`
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 375px;
  background-color: ${standardColours.white};
  z-index: ${zIndexLayers.fifth};
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '100%')});
  transition: ${standardTransition('transform')};
`;

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: ${zIndexLayers.first};
  background-color: ${standardColours.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid ${standardColours.transparentBlack(0.2)};
`;

const StyledBackButton = styled.button`
  font-weight: ${fontWeights.regular};
  background: none;
  border: none;

  span {
    position: relative;
    padding-left: 42px;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
    }

    &:before {
      height: 2px;
      width: 30px;
      background-color: ${standardColours.black};
      transform: translateY(-50%);
    }

    &:after {
      height: 8px;
      width: 8px;
      border: 2px solid ${standardColours.black};
      border-top: 0;
      border-right: 0;
      transform: translateY(-50%) rotate(45deg);
    }
  }
`;

const StyledHeading = styled.h3`
  ${fontSize(22)};

  ${minBreakpointQuery.small`
    ${fontSize(24)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}
`;

const StyledClose = styled.button`
  height: 40px;
  width: 40px;
  background: ${standardColours.transparentBlack(0.1)};
  border: none;
  border-radius: 50%;

  &:before {
    content: '✕';
    font-weight: ${fontWeights.bold};
    ${fontSize(18)};
  }

  span {
    ${visuallyHidden()};
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  overflow: auto;
`;

export const StyledFooter = styled.footer`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  padding: 22px 15px;
  background-color: ${brandColours.primary};
`;

export const StyledSummaryItem = styled.p`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${standardColours.white};
`;

export const StyledNote = styled.p`
  margin: 16px 0;
  color: ${standardColours.white};
  ${fontSize(11)};
  line-height: 1.5;
  opacity: 0.5;
`;

const PopOutSidebar = ({
  heading,
  children,
  incBackButton,
  isOpen,
  toggleOpen,
  locale = 'en',
  ...props
}) => (
  <StyledPopOutSidebar isOpen={isOpen} {...props}>
    <StyledHeader>
      {incBackButton && (
        <StyledBackButton type="button" onClick={toggleOpen}>
          <span>{translateString('sidebar.back', locale)}</span>
        </StyledBackButton>
      )}
      {heading && <StyledHeading>{heading}</StyledHeading>}
      <StyledClose type="button" onClick={toggleOpen}>
        <span>Close</span>
      </StyledClose>
    </StyledHeader>
    {children}
  </StyledPopOutSidebar>
);

export default PopOutSidebar;
